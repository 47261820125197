"use client"
import { DialogProps } from "@mui/material"
import React, { useCallback, useContext, useState } from "react"
import { Confirm } from "./Confirm"

export interface ConfirmProviderProps {
  children: React.ReactNode
}

export interface ConfirmMessageProps {
  title?: string
  message?: string
}

export interface ConfirmOptions extends ConfirmMessageProps {
  content?: React.ReactNode
  actionCallback?: (confirm: boolean) => void
  dialogProps?: DialogProps
}

export interface ConfirmContextProps {
  openDialog?: (options: ConfirmOptions) => void
}
const ConfirmContext = React.createContext<ConfirmContextProps>({})

export const ConfirmProvider = ({ children }: ConfirmProviderProps) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogConfig, setDialogConfig] = useState<ConfirmOptions>({})

  const openDialog = useCallback(
    ({
      title,
      message,
      content,
      actionCallback,
      dialogProps,
    }: ConfirmOptions) => {
      setDialogOpen(true)
      setDialogConfig({ title, message, content, actionCallback, dialogProps })
    },
    [],
  )

  const resetDialog = useCallback(() => {
    setDialogOpen(false)
    setDialogConfig({})
  }, [])

  const onConfirm = useCallback(() => {
    resetDialog()
    dialogConfig?.actionCallback?.(true)
  }, [dialogConfig])

  const onDismiss = useCallback(() => {
    resetDialog()
    dialogConfig?.actionCallback?.(false)
  }, [dialogConfig])

  return (
    <ConfirmContext.Provider value={{ openDialog }}>
      <Confirm
        open={dialogOpen}
        title={dialogConfig.title}
        message={dialogConfig.message}
        content={dialogConfig.content}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
        dialogProps={dialogConfig.dialogProps}
      />
      {children}
    </ConfirmContext.Provider>
  )
}

export const useConfirm = (dialogProps?: DialogProps) => {
  const { openDialog } = useContext(ConfirmContext)
  const confirm = (message: ConfirmMessageProps) => {
    return new Promise((res) => {
      openDialog?.({ actionCallback: res, dialogProps, ...message })
    })
  }

  return { confirm }
}
