import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
} from "@mui/material"
export interface ConfirmProps {
  open: boolean
  title?: string
  message?: string
  content?: React.ReactNode
  onConfirm: () => void
  onDismiss: () => void
  dialogProps?: DialogProps
}
export const Confirm = (props: ConfirmProps) => {
  const { open, title, message, content, onConfirm, onDismiss, dialogProps } =
    props

  const onClose = (e: never, reason: string) => {
    if (reason !== "backdropClick") onDismiss()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"xs"}
      fullWidth
      aria-labelledby="confirm"
      aria-describedby="Are you sure?"
      {...dialogProps}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      {content || (message && <DialogContent>{message}</DialogContent>)}
      <Divider />
      <DialogActions disableSpacing>
        <Button onClick={onDismiss} data-testid="cancelBtn" aria-label="Cancel">
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          data-testid="confirmBtn"
          aria-label="Confirm"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
