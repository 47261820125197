import { SearchFieldCollectionMappings, SearchFieldId } from "./Constants"

export interface CollectionSetting {
  queryAttributes: string
  valueAttribute: string
  displayAttr: string
  placeholder: string
  customOption?: (item: object) => string
  orderBy?: string
}

const convertTitleCase = (value: string) => {
  if (!value) {
    return value
  }

  return value
    .split(" ")
    .map((ss) => {
      if (!ss) {
        return ""
      }
      if (ss.length < 2) {
        return ss
      }
      return ss[0].toUpperCase() + ss.slice(1).toLowerCase()
    })
    .join(" ")
}

export const getCollectionDefaultSettings = (
  collectionName: string,
): CollectionSetting | undefined => {
  if (
    SearchFieldCollectionMappings[SearchFieldId.CountryCode] === collectionName
  ) {
    return {
      queryAttributes: "name,label,alpha_2_code,capital",
      valueAttribute: "alpha_2_code",
      displayAttr: "label",
      placeholder: "Select country",
      customOption: (hit: object) => {
        return (
          hit["label" as keyof object] +
          ` (${hit["alpha_2_code" as keyof object]})`
        )
      },
    }
  }
  if (
    SearchFieldCollectionMappings[SearchFieldId.WineColor] === collectionName
  ) {
    return {
      queryAttributes: "label, search_id",
      displayAttr: "label",
      valueAttribute: "search_id",
      placeholder: "Select color",
    }
  }
  if (
    SearchFieldCollectionMappings[SearchFieldId.WineCategory] === collectionName
  ) {
    return {
      queryAttributes: "label, search_id",
      displayAttr: "label",
      valueAttribute: "search_id",
      placeholder: "Select a category",
    }
  }
  if (SearchFieldCollectionMappings[SearchFieldId.HsCode] === collectionName) {
    return {
      queryAttributes: "label,code,parents",
      displayAttr: "label",
      valueAttribute: "code",
      placeholder: "Select hs code",
    }
  }
  if (SearchFieldCollectionMappings[SearchFieldId.Package] === collectionName) {
    return {
      queryAttributes: "plural_label,code",
      displayAttr: "plural_label",
      valueAttribute: "code",
      placeholder: "Select package type",
    }
  }
  if (
    SearchFieldCollectionMappings[SearchFieldId.FreeSalePremise] ===
    collectionName
  ) {
    return {
      queryAttributes:
        "name,street,city,search_id,identifier_value,identifier_id,identifier_description,premise_id",
      displayAttr: "name",
      valueAttribute: "search_id",
      placeholder: "",
      orderBy: "name:asc",
      customOption: (hit: object) => {
        return `${hit["name" as keyof object]} - ${hit["identifier_value" as keyof object]}`
      },
    }
  }
  if (
    SearchFieldCollectionMappings[SearchFieldId.OrganicPremise] ===
    collectionName
  ) {
    return {
      queryAttributes: "name,street,city,search_id,organic_management_plan_id",
      displayAttr: "name",
      valueAttribute: "search_id",
      placeholder: "",
      customOption: (hit: object) => {
        return `${hit["name" as keyof object]} - ${hit["organic_management_plan_id" as keyof object]}`
      },
    }
  }
  if (
    SearchFieldCollectionMappings[SearchFieldId.Verifier] === collectionName
  ) {
    return {
      queryAttributes: "name,trading_as,search_id,recognised_agency_codes,city",
      displayAttr: "name",
      valueAttribute: "search_id",
      placeholder: "",
    }
  }
  if (
    SearchFieldCollectionMappings[SearchFieldId.WineSpecialProcess] ===
    collectionName
  ) {
    return {
      queryAttributes: "label,search_id",
      displayAttr: "label",
      valueAttribute: "search_id",
      placeholder: "Select a process",
    }
  }
  if (
    SearchFieldCollectionMappings[SearchFieldId.WineGrapeVariety] ===
    collectionName
  ) {
    return {
      queryAttributes: "label,search_id",
      displayAttr: "label",
      valueAttribute: "search_id",
      placeholder: "Select a variety",
    }
  }
  if (
    SearchFieldCollectionMappings[SearchFieldId.WineStyle] === collectionName
  ) {
    return {
      queryAttributes: "label,search_id",
      displayAttr: "label",
      valueAttribute: "search_id",
      placeholder: "Select a style",
    }
  }
  if (
    SearchFieldCollectionMappings[SearchFieldId.UnLocode] === collectionName
  ) {
    return {
      queryAttributes: "country_code,country,location_code,location,code",
      displayAttr: "code",
      valueAttribute: "code",
      customOption: (hit: object) =>
        convertTitleCase(hit["country" as keyof object]) +
        ` - ${convertTitleCase(hit["location" as keyof object])} ` +
        `(${hit["code" as keyof object]})`,
      placeholder: "",
      orderBy: "code:asc",
    }
  }
  if (
    SearchFieldCollectionMappings[SearchFieldId.Commodity] === collectionName
  ) {
    return {
      queryAttributes: "label,search_id",
      displayAttr: "label",
      valueAttribute: "search_id",
      placeholder: "Select commodity type",
    }
  }
  if (
    SearchFieldCollectionMappings[SearchFieldId.GeographicIndication] ===
    collectionName
  ) {
    return {
      queryAttributes: "label,search_id",
      displayAttr: "label",
      valueAttribute: "search_id",
      placeholder: "Select geographic indications",
    }
  }
  if (
    SearchFieldCollectionMappings[SearchFieldId.Document] === collectionName
  ) {
    return {
      queryAttributes: "label,search_id",
      displayAttr: "label",
      valueAttribute: "search_id",
      placeholder: "Select document type",
    }
  }
  if (
    SearchFieldCollectionMappings[SearchFieldId.BottleSize] === collectionName
  ) {
    return {
      queryAttributes: "label",
      displayAttr: "label",
      valueAttribute: "id",
      placeholder: "Bottle size",
    }
  }
  if (
    SearchFieldCollectionMappings[SearchFieldId.CertificateDeliveryMethod] ===
    collectionName
  ) {
    return {
      valueAttribute: "search_id",
      queryAttributes: "label,search_id",
      displayAttr: "label",
      placeholder: "Select certificate delivery method",
    }
  }
  if (SearchFieldCollectionMappings[SearchFieldId.WSMPS] === collectionName) {
    return {
      valueAttribute: "wsmp_number",
      queryAttributes: "recognized_agency,wsmp_number",
      displayAttr: "recognized_agency",
      placeholder: "Select WSMP",
      customOption: (hit: object) => {
        const orgName = hit["accountInfo" as keyof object]?.["name"]
        return `${hit["wsmp_number" as keyof object]}${orgName ? ` - ${orgName}` : ""}`
      },
    }
  }
  if (
    SearchFieldCollectionMappings[SearchFieldId.EstarLaboratory] ===
    collectionName
  ) {
    return {
      queryAttributes: "name,search_id",
      displayAttr: "name",
      valueAttribute: "search_id",
      placeholder: "Select chemistry laboratory",
    }
  }
  if (
    SearchFieldCollectionMappings[SearchFieldId.EstarWineTest] ===
    collectionName
  ) {
    return {
      queryAttributes: "name,search_id",
      displayAttr: "name",
      valueAttribute: "search_id",
      placeholder: "Select wine test",
    }
  }

  if (
    SearchFieldCollectionMappings[SearchFieldId.WineIngredient] ===
    collectionName
  ) {
    return {
      queryAttributes: "*",
      displayAttr: "ingredient",
      valueAttribute: "ingredient",
      placeholder: "Type to add ingredient",
    }
  }
  if (SearchFieldCollectionMappings[SearchFieldId.Product] === collectionName) {
    return {
      queryAttributes: "search_id,product_number,exporter_reference",
      displayAttr: "product_number",
      valueAttribute: "search_id",
      customOption: (item: object) => {
        return [
          item["product_number" as keyof object],
          item["exporter_reference" as keyof object],
        ].join(" - ")
      },
      placeholder: "Select a product",
    }
  }
  if (
    SearchFieldCollectionMappings[SearchFieldId.Exporter] === collectionName
  ) {
    return {
      queryAttributes: "*",
      displayAttr: "name",
      valueAttribute: "search_id",
      placeholder: "Select a product",
    }
  }

  console.error(
    "missing collection config for collection name:",
    collectionName,
  )
  return undefined
}
