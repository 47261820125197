"use client"
import axios, { AxiosError, AxiosProgressEvent, AxiosResponse } from "axios"
import { useState } from "react"

export const useUpload = () => {
  const controller: AbortController = new AbortController()
  const [uploading, setUploading] = useState(false)
  const [progress, setProgress] = useState<number>(0)
  const [result, setResult] = useState<AxiosResponse>()
  const [error, setError] = useState<AxiosError>()
  const upload = async (
    file: File,
    options: { uploadUrl: string; contentType: string },
  ) => {
    if (!file) {
      return { success: false, error: "No file provided" }
    }

    setUploading(true)

    try {
      const uploadResult: AxiosResponse = await axios.put(
        options.uploadUrl,
        file,
        {
          headers: {
            "Content-Type": options.contentType,
          },
          onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            if (progressEvent.total) {
              setProgress(
                Math.round((100 * progressEvent.loaded) / progressEvent.total),
              )
            }
          },
          signal: controller.signal,
        },
      )

      setResult(uploadResult)
      setUploading(false)

      return { success: true }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError(error)
      } else {
        console.error(error)
      }
      setUploading(false)
      return { success: false, error }
    }
  }
  const cancelUpload = () => {
    controller.abort()
  }

  return { result, uploading, progress, error, upload, cancelUpload }
}
