import { useSearchParams } from "next/navigation"
import { useCallback, useMemo } from "react"

export const useSearchQuery = () => {
  const searchParams = useSearchParams()

  const createQueryString = useCallback((json: object) => {
    const urlSearchQuery = new URLSearchParams()
    Object.entries(json).forEach(([key, value]) => {
      if (value) {
        urlSearchQuery.set(key as string, value as string)
      }
    })
    return urlSearchQuery.toString()
  }, [])

  const query = useMemo(() => {
    const json: { [key: string]: string | string[] | null } = {}
    searchParams.forEach((value, key: string) => {
      try {
        json[key] = JSON.parse(value)
      } catch (error) {
        json[key] = searchParams.get(key)
      }
    })
    return json
  }, [searchParams])

  return { query, createQueryString }
}
