"use client"

import { createContext } from "react"

export interface TypesenseProviderProps {
  typesenseHost: string
  typesenseApiKeyMutation?: string
  typesensePartyApiKeyMutation?: string
  children: React.ReactNode
}

export interface TypesenseContextProps {
  typesenseHost: string
  typesenseApiKeyMutation?: string
  typesensePartyApiKeyMutation?: string
}

export const TypesenseContext = createContext<TypesenseContextProps>({
  typesenseHost: "",
  typesenseApiKeyMutation: "",
  typesensePartyApiKeyMutation: "",
})

export const TypesenseProvider = (props: TypesenseProviderProps) => {
  if (!props.typesenseApiKeyMutation || !props.typesensePartyApiKeyMutation) {
    console.warn(
      "for typesense, recommend to config its typesenseApiKeyMutation and typesensePartyApiKeyMutation",
    )
  }
  return (
    <TypesenseContext.Provider
      value={{
        typesenseHost: props.typesenseHost,
        typesenseApiKeyMutation: props.typesenseApiKeyMutation || "",
        typesensePartyApiKeyMutation: props.typesensePartyApiKeyMutation || "",
      }}
    >
      {props.children}
    </TypesenseContext.Provider>
  )
}
