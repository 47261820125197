import { useAlert } from "@tc/ui-shared/hooks"
import {
  Document,
  MenuItemKeyValuePair,
  SearchParameters,
} from "@tc/ui-shared/utils"
import { TypesenseContext } from "@tc/ui-typesense-client"
import { useSearchParams } from "next/navigation"
import { useCallback, useContext, useEffect, useRef } from "react"
import { useMutation } from "urql"

const TYPESENSE_APIKEY_IN_COOKIE = "type-sense-apikey"

export const useSearch = (apiKeyMutation: string) => {
  const alert = useAlert()
  const context = useContext(TypesenseContext)
  const [setNewApiKeyMutation, newApiKeyMutation] = useMutation(
    apiKeyMutation.toString(),
  )
  const apiKeyRef = useRef("")
  const initialized = useRef(false)
  const searchParams = useSearchParams()
  useEffect(() => {
    if (initialized.current) return

    // Need to use local storage to improve the performance when loading the page because retrieving Typesense key for the first time took 3 seconds
    // backend/service/search-graphql-api/src/functions/new-api-key.ts
    const apiKeyItem = localStorage.getItem(TYPESENSE_APIKEY_IN_COOKIE)
    if (apiKeyItem) {
      try {
        const apiKeyObject: {
          apiKey: string
          expires: string
        } = JSON.parse(apiKeyItem)
        if (new Date(apiKeyObject.expires).getTime() > new Date().getTime()) {
          apiKeyRef.current = apiKeyObject.apiKey
          return
        }
      } catch (error) {
        console.log(error)
      }
    }

    newApiKeyMutation().then((result) => {
      apiKeyRef.current = result.data?.newSearchApiKey.apiKey
      localStorage.setItem(
        TYPESENSE_APIKEY_IN_COOKIE,
        JSON.stringify(result.data?.newSearchApiKey),
      )
    })

    initialized.current = true
  }, [])
  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams.toString())
      params.set(name, value)

      return params.toString()
    },
    [searchParams],
  )
  const searchData = async (request: {
    collectionName: string
    searchParameters: SearchParameters
  }) => {
    if (!apiKeyRef) return []
    try {
      // TODO interim code, use typesense package to call Typesense API
      const response = await fetch(
        `${context.typesenseHost}/search/collections/${request.collectionName}/documents/search?${createQueryString("q", request.searchParameters.q)}&${createQueryString("query_by", request.searchParameters.query_by)}&${createQueryString("per_page", request.searchParameters.per_page?.toString() ?? "10")}`,
        {
          headers: {
            "X-Typesense-Api-Key": apiKeyRef.current,
          },
        },
      )
      const data = await response.json()

      if (!data?.hits || data.hits.length === 0) {
        return []
      } else {
        return data.hits.map((hit: { document: Document }) => {
          const document: Document = hit.document as Document
          return { value: document.id, label: document.label }
        })
      }
    } catch (error) {
      if (error instanceof Error) {
        alert.error(error.message)
      }

      alert.error("There is an error when retrieving data")
      return []
    }
  }

  const getSearchCollectionData = async (collectionNames: string[]) => {
    const collectionData: { key: string; value: MenuItemKeyValuePair[] }[] = []

    await Promise.all(
      collectionNames.map(async (collectionName: string) => {
        const searchParameters: SearchParameters = {
          q: "*",
          query_by: "label",
          per_page: 250,
        }
        const searchResult = await searchData({
          collectionName: collectionName,
          searchParameters: searchParameters,
        })

        collectionData.push({
          key: collectionName,
          value: searchResult ?? [],
        })
      }),
    )

    return collectionData
  }

  return {
    getSearchCollectionData,
    searchData,
    keyFetching: setNewApiKeyMutation.fetching,
  }
}
