"use client"

import { useContext, useEffect, useMemo, useState } from "react"
import TypesenseInstantSearchAdapter, {
  TypesenseInstantsearchAdapterOptions,
} from "typesense-instantsearch-adapter"
import { useMutation } from "urql"
import { SearchFieldId } from "./Constants"
import { TypesenseContext } from "./TypesenseProvider"

export interface useUiTypesenseClientProps {
  /**
   * @deprecated recommend to set api key mutation through TypesenseProvider
   */
  apiKeyMutation?: string
  typesenseUrl: string
  searchParams?: object
  collectionId?: SearchFieldId
}

interface TypesenseHookReturn {
  fetching: boolean
  adapter?: TypesenseInstantSearchAdapter
  adapterProps?: TypesenseInstantsearchAdapterOptions
  apiKey: string
}

const PermissionSpecificCollections = [
  SearchFieldId.ExportCertificate,
  SearchFieldId.Product,
]

const TYPESENSE_APIKEY_IN_STORAGE = "typesense-apiKey"
const TYPESENSE_PARTY_APIKEY_IN_STORAGE = "typesense-party-apiKey"

export const clearApiKey = () => {
  localStorage.removeItem(TYPESENSE_APIKEY_IN_STORAGE)
  localStorage.removeItem(TYPESENSE_PARTY_APIKEY_IN_STORAGE)
}

export function useTypesenseClient(
  props: useUiTypesenseClientProps,
): TypesenseHookReturn {
  const [fetching, setFetching] = useState(true)
  const [adapter, setAdapter] = useState<TypesenseInstantSearchAdapter>()
  const [adpaterProps, setAdapterProps] =
    useState<TypesenseInstantsearchAdapterOptions>()

  const context = useContext(TypesenseContext)

  if (
    props.collectionId &&
    (!context.typesenseApiKeyMutation || !context.typesensePartyApiKeyMutation)
  ) {
    throw new Error(
      "please set typesense api key mutation and party api key mutation in typesense provider",
    )
  }

  const [storageKeyName, setStorageKeyName] = useState(
    TYPESENSE_APIKEY_IN_STORAGE,
  )

  const mutationString = useMemo(() => {
    let mutationString =
      props.apiKeyMutation || context.typesenseApiKeyMutation || ""
    if (props.collectionId) {
      if (PermissionSpecificCollections.includes(props.collectionId)) {
        mutationString = context.typesensePartyApiKeyMutation || ""
        setStorageKeyName(TYPESENSE_PARTY_APIKEY_IN_STORAGE)
      } else {
        mutationString = context.typesenseApiKeyMutation || ""
        setStorageKeyName(TYPESENSE_APIKEY_IN_STORAGE)
      }
    }

    return mutationString
  }, [props.collectionId])

  const [newSearchApiKeyResult, updateNewSearchApiKeyResult] =
    useMutation(mutationString)

  const [apiKey, setApiKey] = useState("")

  useEffect(() => {
    if (newSearchApiKeyResult && newSearchApiKeyResult.data) {
      const key =
        newSearchApiKeyResult.data?.newSearchApiKey?.apiKey ||
        newSearchApiKeyResult.data?.newPartyApiKey?.apiKey
      const expires =
        newSearchApiKeyResult.data?.newSearchApiKey?.expires ||
        newSearchApiKeyResult.data?.newPartyApiKey?.expires
      localStorage.setItem(
        storageKeyName,
        JSON.stringify({
          apiKey: key,
          expires: expires,
        }),
      )

      setApiKey(key)
    }
  }, [newSearchApiKeyResult.fetching])

  useEffect(() => {
    const apiKeyItem = localStorage.getItem(storageKeyName)
    if (apiKeyItem) {
      const apiKeyObject: {
        apiKey: string
        expires: string
      } = JSON.parse(apiKeyItem)

      if (new Date(apiKeyObject.expires).getTime() < new Date().getTime()) {
        localStorage.removeItem(storageKeyName)
        updateNewSearchApiKeyResult()
        return
      }

      setApiKey(apiKeyObject.apiKey)
    } else {
      updateNewSearchApiKeyResult()
    }
  }, [])

  useEffect(() => {
    if (!apiKey) {
      return
    }
    const typesenseAdapter = new TypesenseInstantSearchAdapter({
      server: {
        apiKey: apiKey,
        sendApiKeyAsQueryParam: false,
        nodes: [
          {
            url: props.typesenseUrl,
          },
        ],
      },
      additionalSearchParameters: props.searchParams || {},
    })
    setAdapter(typesenseAdapter)
    const options: TypesenseInstantsearchAdapterOptions = {
      server: {
        apiKey: apiKey,
        sendApiKeyAsQueryParam: false,
        nodes: [
          {
            url: props.typesenseUrl,
          },
        ],
      },
      additionalSearchParameters: props.searchParams || {},
    }
    setAdapterProps(options)
    //typesenseAdapter.updateConfiguration(options)
    setFetching(newSearchApiKeyResult.fetching)
  }, [apiKey])

  return {
    fetching: fetching,
    adapter,
    adapterProps: adpaterProps,
    apiKey: apiKey,
  }
}
