import { useSnackbar, VariantType } from "notistack"
import React, { startTransition, useCallback } from "react"

export interface AlertProps {
  children?: React.ReactNode
}

export const useAlert = () => {
  const { enqueueSnackbar } = useSnackbar()
  const push = useCallback(
    (message: string, variant: VariantType, persist = false) => {
      startTransition(() => {
        enqueueSnackbar(message, {
          variant,
          persist,
        })
      })
    },
    [],
  )

  const success = useCallback(
    (message: string, persist?: boolean) => push(message, "success", persist),
    [],
  )
  const warning = useCallback(
    (message: string, persist?: boolean) => push(message, "warning", persist),
    [],
  )
  const info = useCallback(
    (message: string, persist?: boolean) => push(message, "info", persist),
    [],
  )
  const error = useCallback(
    (message: string, persist?: boolean) => push(message, "error", persist),
    [],
  )
  const message = useCallback(
    (message: string, persist?: boolean) => push(message, "default", persist),
    [],
  )
  return { success, warning, info, error, message }
}
